import { useContext, useRef } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import Label from "../../Label";
import Checkbox from "../../Checkbox";
import DynamicCreatable from "../../DynamicCreatable";

import OptionsContext from "../../../contexts/OptionsContext";

const ReportOptions = () => {
  const { options, setOptions } = useContext(OptionsContext);

  const refTest = useRef(null);
  console.log(refTest);

  return (
    <>
      <Label label="Report ID">
        <DynamicCreatable
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          value={options.reports.current}
          placeholder="Start typing..."
          onChange={(value, list) => {
            setOptions({
              type: "hydrate",
              payload: {
                // slice -> store most recent 5
                reports: { current: value, list: list.slice(-5) },
              },
            });
          }}
          defaultOptions={options.reports.list}
        />
      </Label>
      <Label label="Date range">
        <DatePicker
          selected={moment(options.reportStart, "YYYY-MM-DD").toDate()}
          onChange={(date) => {
            setOptions({
              type: "hydrate",
              payload: {
                reportStart: undefined,
                reportEnd: undefined,
              },
            });

            if (date && Array.isArray(date)) {
              const formatDateStart = moment(date[0]).format("YYYY-MM-DD");
              const formatDateEnd = date[1]
                ? moment(date[1]).format("YYYY-MM-DD")
                : undefined;
              setOptions({
                type: "hydrate",
                payload: {
                  reportStart: formatDateStart,
                  reportEnd: formatDateEnd,
                },
              });
            }
          }}
          startDate={moment(options.reportStart, "YYYY-MM-DD").toDate()}
          endDate={
            options.reportEnd
              ? moment(options.reportEnd, "YYYY-MM-DD").toDate()
              : null
          }
          selectsRange
          dateFormat="dd.MM.yyyy"
          inline
        />
      </Label>
      <Label label="Preis anzeigen">
        <Checkbox
          checked={options.showPrice}
          onChange={(value) => {
            setOptions({ type: "hydrate", payload: { showPrice: value } });
          }}
        />
      </Label>
      <Label label="Adresse anzeigen">
        <Checkbox
          checked={options.showAddress}
          onChange={(value) => {
            setOptions({ type: "hydrate", payload: { showAddress: value } });
          }}
        />
      </Label>
      <Label label="Kopf-/Fusszeile anzeigen">
        <Checkbox
          checked={options.showHeaderFooter}
          onChange={(value) => {
            setOptions({
              type: "hydrate",
              payload: { showHeaderFooter: value },
            });
          }}
        />
      </Label>
      <Label label="Quality">
        <Select
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          isClearable
          value={options.quality}
          onChange={(value) => {
            if (value) {
              setOptions({
                type: "hydrate",
                payload: { quality: value as any },
              });
            } else {
              setOptions({
                type: "reset",
                payload: "quality",
              });
            }
          }}
          options={[
            {
              value: "print",
              label: "Print",
            },
            {
              value: "web",
              label: "Web",
            },
          ]}
        />
      </Label>
    </>
  );
};

export default ReportOptions;
