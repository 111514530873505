import { useCallback, useState } from "react";
import {
  fetchToken,
  FetchTokenArgs,
  recoverAccessTokensFromLocal,
} from "utils/auth";

function useAuth() {
  const [authenticated, setAuthenticated] = useState(
    recoverAccessTokensFromLocal().length ? true : false,
  );

  const requestTokens = useCallback(async (args: FetchTokenArgs) => {
    await fetchToken(args);
    setAuthenticated(true);
  }, []);

  return { authenticated, requestTokens };
}

export default useAuth;
