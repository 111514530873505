import { useContext } from "react";
import Checkbox from "../../Checkbox";
import Label from "../../Label";
import Select from "react-select";
import Async from "react-select/async";
import qs from "qs";
import _ from "lodash";
import DynamicCreatable from "../../DynamicCreatable";
import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import OptionsContext from "contexts/OptionsContext";
import { axiosInstance } from "utils/axios";

export interface PropertyInterface {
  id: string;
}

const PropertyOptions = () => {
  const { options, setOptions } = useContext(OptionsContext);
  const fetchProperties = async (input: string) => {
    try {
      const propertyQuery = qs.stringify({
        "order-by": [
          {
            type: "customorderby",
            field: "created",
            alias: "",
            direction: "desc",
          },
        ],
        filter: [
          {
            type: "search",
            value: input,
          },
          {
            type: "in",
            field: "propertyType",
            where: "and",
            values: ["property", "project"],
          },
        ],
        pagesize: 10,
      });

      const response = await axiosInstance.get(
        `/${_.get(options.company, "value")}/property?${propertyQuery}`,
      );
      const json = response.data;
      const properties = _.get(json, "_embedded.property");
      if (properties) {
        return properties.map((property: PropertyInterface) => ({
          label: `${_.get(property, "_embedded.contents[0].name")} (${
            property.id
          })`,
          value: property.id,
          raw: property,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const propertyRawUrl = (raw: PropertyInterface) => {
    const json = JSON.stringify(raw);
    const jsonBlob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(jsonBlob);
    return url;
  };
  return (
    <>
      <Label label="Property">
        <Async
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          isClearable
          placeholder="Start typing..."
          value={options.property}
          loadOptions={fetchProperties}
          onChange={(property) => {
            if (property) {
              setOptions({
                type: "hydrate",
                payload: { property: property },
              });
            } else {
              setOptions({
                type: "reset",
                payload: "property",
              });
            }
          }}
        />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={propertyRawUrl(_.get(options, "property.raw"))}
        >
          property json (disable adblock)
        </a>
        <a
          href={propertyRawUrl(_.get(options, "property.raw"))}
          download={"property.json"}
        >
          <FontAwesomeIcon fixedWidth icon={faDownload} />
        </a>
      </Label>
      <Label label="Type">
        <DynamicCreatable
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          value={options.subTypes.current}
          keepOriginalValue
          onChange={(value, list) => {
            setOptions({
              type: "hydrate",
              payload: {
                // store last 5 used
                subTypes: { current: value, list: list.slice(-5) },
              },
            });
          }}
          defaultOptions={options.subTypes.list}
        />
      </Label>
      <Label label="Show price">
        <Checkbox
          checked={options.showPrice}
          onChange={(value) => {
            setOptions({ type: "hydrate", payload: { showPrice: value } });
          }}
        />
      </Label>
      <Label label="Show address">
        <Checkbox
          checked={options.showAddress}
          onChange={(value) => {
            setOptions({ type: "hydrate", payload: { showAddress: value } });
          }}
        />
      </Label>
      <Label label="Show header and footer">
        <Checkbox
          checked={options.showHeaderFooter}
          onChange={(value) => {
            setOptions({
              type: "hydrate",
              payload: { showHeaderFooter: value },
            });
          }}
        />
      </Label>
      <Label label="Show affordability">
        <Checkbox
          checked={options.showAffordabilityCalculations}
          onChange={(value) => {
            setOptions({
              type: "hydrate",
              payload: { showAffordabilityCalculations: value },
            });
          }}
        />
      </Label>
      <Label label="Orientation">
        <Select
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          value={options.format}
          isClearable
          onChange={(value) => {
            if (value) {
              if (value) {
                setOptions({
                  type: "hydrate",
                  payload: { format: value as any },
                });
              }
            } else {
              setOptions({
                type: "reset",
                payload: "format",
              });
            }
          }}
          options={[
            {
              value: "portrait",
              label: "Portrait",
            },
            {
              value: "landscape",
              label: "Landscape",
            },
          ]}
        />
      </Label>
      <Label label="Quality">
        <Select
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          isClearable
          value={options.quality}
          onChange={(value) => {
            if (value) {
              setOptions({
                type: "hydrate",
                payload: { quality: value as any },
              });
            } else {
              setOptions({
                type: "reset",
                payload: "quality",
              });
            }
          }}
          options={[
            {
              value: "print",
              label: "Print",
            },
            {
              value: "web",
              label: "Web",
            },
          ]}
        />
      </Label>
    </>
  );
};

export default PropertyOptions;
