import { useContext, useState, useRef, useEffect } from "react";

import _ from "lodash";
import { parse } from "qs";

import Nav from "./PdfBuilder/Nav";
import Label from "./Label";
import Async from "react-select/async";
import Select from "react-select";
import PropertyOptions from "./PdfBuilder/Options/PropertyOptions";
import ReportOptions from "./PdfBuilder/Options/ReportOptions";
import ContactOptions from "./PdfBuilder/Options/ContactOptions";
import JobsOptions from "./PdfBuilder/Options/JobsOptions";
import LogbookOptions from "./PdfBuilder/Options/LogbookOptions";
// import useSavedOPtions from "./PdfBuilder/useSavedOptions";
import {
  faLink,
  faCog,
  faSync,
  faEye,
  faEyeSlash,
  faSave,
  faBrush,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut } from "@fortawesome/pro-light-svg-icons";

import OptionsContext from "contexts/OptionsContext";
import PdfBuilderToolContext from "contexts/PdfBuilderToolContext";
import { useLocation, useNavigate } from "react-router-dom";
import Dim from "./Dim";
import SavedOptions from "./PdfBuilder/SavedOptions";
import EnvSelect from "./PdfBuilder/EnvSelect";
import { axiosInstance } from "utils/axios";
import { clearAuthTokens } from "utils/auth";

interface companyInterface {
  legalName: string;
  id: string;
}

// interface PdfBuilderProps {}

const IFRAME_BG_COLORS = ["transparent", "white", "black", "red", "green"];

const PdfBuilder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { options, setOptions } = useContext(OptionsContext);
  const { toolOptions, setToolOptions } = useContext(PdfBuilderToolContext);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [savedOptionsOpen, setSavedOptionsOpen] = useState(false);
  const copyRef = useRef<HTMLInputElement>(null);
  const [copyState, setCopyState] = useState({
    link: "",
    state: "copy link",
  });

  useEffect(() => {
    if (location.search) {
      const query = parse(location.search, { ignoreQueryPrefix: true });
      if (query.shortened) {
        fetch(
          `https://jsonbox.io/box_a5ace32abad6182b1284/${query.shortened}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          },
        )
          .then((res) => res.json())
          .then((res) => {
            if (res[0]) {
              setOptions({
                type: "hydrate",
                payload: { ...res[0] },
              });
            } else {
              throw new Error("Upsi dupsi daisy");
            }
            navigate("");
          })
          .catch((err) => console.error(err));
      }
    }
  }, [location, navigate, setOptions]);

  const additionOptions = (value: string) => {
    switch (value) {
      case "property":
        return <PropertyOptions />;
      case "report":
        return <ReportOptions />;
      case "contact":
        return <ContactOptions />;
      case "jobs":
        return <JobsOptions />;
      case "logbook":
        return <LogbookOptions />;
      default:
        return <Label label="No additional options found." />;
    }
  };

  const fetchCompanies = async (input: string) => {
    try {
      const response = await axiosInstance.get(
        `/dev/ca-company?page_size=10&s=${input}&products=casaone`,
      );

      const json = response.data;
      const companies = _.get(json, "_embedded.ca_company");
      if (companies) {
        return companies.map((company: companyInterface) => ({
          label: `${company.legalName} (${company.id})`,
          value: company.id,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Nav
        items={[
          {
            group: "left",
            icon: faBrush,
            onItemClick: () => {
              const currIndexSearch = IFRAME_BG_COLORS.findIndex(
                (clr) => options.previewBgColor === clr,
              );
              const currIndex = currIndexSearch === -1 ? 0 : currIndexSearch;
              const nextIndex =
                currIndex + 1 > IFRAME_BG_COLORS.length - 1 ? 0 : currIndex + 1;

              setOptions({
                type: "hydrate",
                payload: { previewBgColor: IFRAME_BG_COLORS[nextIndex] },
              });
            },
            iconColor: options.previewBgColor,
          },
          {
            group: "left",
            icon: toolOptions.showDebug ? faEye : faEyeSlash,
            onItemClick: () => {
              setToolOptions({
                type: "hydrate",
                payload: {
                  showDebug: !toolOptions.showDebug,
                },
              });
            },
          },
          {
            group: "left",
            icon: faSync,
            onItemClick: () => {
              setOptions({ type: "refresh" });
            },
          },
          {
            group: "center",
            icon: faCog,
            onItemClick: () => {
              setSettingsOpen(!settingsOpen);
            },
          },
          {
            group: "right",
            icon: faSave,
            onItemClick: () => {
              setSavedOptionsOpen(!savedOptionsOpen);
            },
          },

          {
            group: "right",
            icon: toolOptions.showPdf ? faEye : faEyeSlash,
            onItemClick: () => {
              setToolOptions({
                type: "hydrate",
                payload: {
                  showPdf: !toolOptions.showPdf,
                },
              });
            },
          },
          {
            group: "right",
            icon: faSignOut,
            onItemClick: () => {
              clearAuthTokens();
            },
          },
        ]}
      />

      {savedOptionsOpen && (
        <Dim
          onOutsideClick={() => {
            setSavedOptionsOpen(false);
          }}
        >
          <SavedOptions />
        </Dim>
      )}
      {settingsOpen && (
        <Dim
          onOutsideClick={() => {
            setSettingsOpen(!settingsOpen);
          }}
        >
          <div className="cursor-default m-auto mb-16 flex flex-wrap max-w-3xl w-full justify-center relative dark:text-white bg-neutral-100 dark:bg-neutral-800 border-2 border-neutral-200 dark:border-neutral-700 rounded-lg">
            <div className="w-full max-w-sm p-3 -m-1">
              <Label label="Company">
                <Async
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  isClearable
                  placeholder="Start typing..."
                  value={options.company}
                  loadOptions={fetchCompanies}
                  onChange={(company) => {
                    if (company) {
                      setOptions({
                        type: "hydrate",
                        payload: { company: company },
                      });
                    } else {
                      setOptions({
                        type: "reset",
                        payload: "company",
                      });
                    }
                  }}
                />
              </Label>
              <Label label="Environment">
                <EnvSelect />
              </Label>
              <Label label="Endpoint">
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={options.endpoint}
                  isClearable
                  onChange={(value) => {
                    if (value) {
                      setOptions({
                        type: "hydrate",
                        payload: { endpoint: value as any },
                      });
                    } else {
                      setOptions({
                        type: "reset",
                        payload: "endpoint",
                      });
                    }
                  }}
                  options={[
                    {
                      value: "property",
                      label: "Property / Project",
                    },
                    {
                      value: "list",
                      label: "List",
                    },
                    {
                      value: "report",
                      label: "Report",
                    },
                    {
                      value: "contact",
                      label: "Contact",
                    },
                    {
                      value: "jobs",
                      label: "Jobs",
                    },
                    {
                      value: "logbook",
                      label: "Logbook",
                    },
                  ]}
                />
              </Label>
              <Label label="Language">
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={options.lang}
                  isClearable
                  onChange={(value) => {
                    if (value) {
                      setOptions({
                        type: "hydrate",
                        payload: { lang: value as any },
                      });
                    } else {
                      setOptions({
                        type: "reset",
                        payload: "lang",
                      });
                    }
                  }}
                  options={[
                    {
                      value: "de",
                      label: "Deutsch",
                    },
                    {
                      value: "en",
                      label: "English",
                    },
                    {
                      value: "it",
                      label: "Italiano",
                    },
                    {
                      value: "fr",
                      label: "Français",
                    },
                  ]}
                />
              </Label>
              <Label label="Template">
                <Select
                  className="my-react-select-container"
                  classNamePrefix="my-react-select"
                  value={options.linieTemplateForce}
                  isClearable
                  onChange={(value) => {
                    if (!value) {
                      setOptions({
                        type: "hydrate",
                        payload: {
                          linieTemplateForce: {
                            value: "default",
                            label: "Company settings",
                          },
                        },
                      });
                    } else if ("value" in value) {
                      setOptions({
                        type: "hydrate",
                        payload: { linieTemplateForce: value },
                      });
                    }
                  }}
                  options={[
                    {
                      value: "default",
                      label: "Company settings",
                    },
                    {
                      value: "linie1",
                      label: "Linie 1",
                    },
                    {
                      value: "linie2",
                      label: "Linie 2",
                    },
                    {
                      value: "linie3",
                      label: "Linie 3",
                    },
                  ]}
                />
              </Label>
              <Label label="Share link">
                <div
                  onClick={async () => {
                    const cleanProperty = {
                      ...options.property,
                      raw: undefined,
                    };

                    // with length of 8 chars
                    const shareID = Math.round(
                      Math.pow(36, 8 + 1) - Math.random() * Math.pow(36, 8),
                    )
                      .toString(36)
                      .slice(1);

                    const optionsJson = JSON.stringify({
                      ...options,
                      property: { ...cleanProperty },
                    });

                    try {
                      setCopyState({ link: "", state: "... loading" });
                      await fetch(
                        `https://jsonbox.io/box_a5ace32abad6182b1284/${shareID}`,
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                          },
                          body: optionsJson,
                        },
                      );
                      const shareUrl = `${window.location.href}?shortened=${shareID}`;
                      if (copyRef.current) {
                        copyRef.current.value = shareUrl;
                        copyRef.current.select();
                        document.execCommand("copy");
                        setCopyState({ link: shareUrl, state: "link copied" });
                      }
                    } catch (error) {
                      console.error(error);
                      setCopyState({ link: "", state: "error" });
                    }
                  }}
                >
                  <input
                    className="input mr-3"
                    ref={copyRef}
                    defaultValue={copyState.link}
                  />
                  <FontAwesomeIcon fixedWidth icon={faLink} /> {copyState.state}
                </div>
              </Label>
            </div>
            <div className="w-full max-w-sm p-3 -m-1">
              {additionOptions(_.get(options.endpoint, "value"))}
            </div>
          </div>
        </Dim>
      )}
    </>
  );
};

export default PdfBuilder;
