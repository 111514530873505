import { useState } from "react";
import CreatableSelect, { CreatableProps } from "react-select/creatable";

interface Option {
  label: string;
  value: string;
}
interface DynamicCreatableProps
  extends Omit<CreatableProps<any, any, any>, "onChange"> {
  onChange: (value: Option, options: Option[]) => void;
  defaultOptions?: Option[];
  value?: Option;
  keepOriginalValue?: boolean;
}
interface State {
  isLoading: boolean;
  options: Option[];
  value?: any;
}

const DynamicCreatable = ({
  defaultOptions = [],
  onChange,
  value,
  keepOriginalValue = false,
  ...passDownProps
}: DynamicCreatableProps) => {
  const createOption = (label: string) => ({
    label,
    value: keepOriginalValue ? label : label.toLowerCase().replace(/\W/g, ""),
  });
  const [state, setState] = useState<State>({
    isLoading: false,
    options: defaultOptions,
    value: value,
  });
  const handleChange = (newValue: any) => {
    const newState = { ...state, value: newValue };
    setState(newState);
    onChange(newValue, newState.options);
  };
  const handleCreate = (inputValue: any) => {
    setState({ ...state, isLoading: true });
    const { options } = state;
    const newOption = createOption(inputValue);
    const newOptions = [...options, newOption];
    setState({
      isLoading: false,
      options: newOptions,
      value: newOption,
    });
    onChange(newOption, newOptions);
  };
  return (
    <CreatableSelect
      {...passDownProps}
      isClearable
      isDisabled={state.isLoading}
      isLoading={state.isLoading}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={state.options}
      value={state.value}
    />
  );
};
export default DynamicCreatable;
