import { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashAlt, faSync } from "@fortawesome/pro-light-svg-icons";
import OptionsContext, { Options } from "../../contexts/OptionsContext";

function SavedOptions() {
  const { options, setOptions } = useContext(OptionsContext);
  const pdfBuilderSaved = localStorage.getItem("pdfBuilderSaved");
  const [savedOptions, setSavedOptions] = useState<Options[]>(
    pdfBuilderSaved ? JSON.parse(pdfBuilderSaved) : [],
  );

  return (
    <div className="m-auto mb-16 pointer-events-none">
      <ol
        className={`mt-8 pointer-events-auto cursor-default relative dark:text-white bg-neutral-100 dark:bg-neutral-800 border-2 border-neutral-200 dark:border-neutral-700 rounded-lg ${
          savedOptions.length ? "" : "hidden"
        }`}
      >
        {savedOptions.map((savedOption, key) => {
          return (
            <li
              key={key}
              className={`p-3 border-b-2 last:border-b-0 first:rounded-t-lg last:rounded-b-lg dark:border-neutral-700 ${
                JSON.stringify(options) === JSON.stringify(savedOption)
                  ? "bg-neutral-200 dark:bg-neutral-900"
                  : ""
              }`}
            >
              <div className="mb-2">
                <strong className="mr-3">
                  <small>
                    {savedOption.company && savedOption.company.label}
                  </small>
                </strong>
                <small className="mr-3">
                  {savedOption.environment && savedOption.environment.label}
                </small>
                <small className="mr-3">
                  {savedOption.endpoint && savedOption.endpoint.label}
                </small>
                <small>
                  {savedOption.subTypes.current &&
                    savedOption.subTypes.current.label}
                </small>
              </div>
              <div>
                <button
                  className="btn-small mr-2"
                  onClick={() => {
                    setOptions({ type: "hydrate", payload: savedOption });
                  }}
                >
                  Select
                </button>
                <button
                  className="btn-small mr-2"
                  onClick={() => {
                    const filteredOptions = savedOptions.map((opt, i) =>
                      i === key ? options : opt,
                    );
                    localStorage.setItem(
                      "pdfBuilderSaved",
                      JSON.stringify(filteredOptions),
                    );
                    setSavedOptions(filteredOptions);
                  }}
                >
                  Update this one to current settings{" "}
                  <FontAwesomeIcon
                    fixedWidth
                    icon={faSync}
                    title="set to current"
                  />
                </button>
                <button
                  className="btn-small"
                  onClick={() => {
                    const filteredOptions = savedOptions.filter(
                      (option, i) => i !== key,
                    );
                    localStorage.setItem(
                      "pdfBuilderSaved",
                      JSON.stringify(filteredOptions),
                    );
                    setSavedOptions(filteredOptions);
                  }}
                >
                  Delete{" "}
                  <FontAwesomeIcon
                    fixedWidth
                    title="remove from list"
                    icon={faTrashAlt}
                  />
                </button>
              </div>
            </li>
          );
        })}
      </ol>

      <button
        className="btn pointer-events-auto"
        onClick={() => {
          localStorage.setItem(
            "pdfBuilderSaved",
            JSON.stringify([...savedOptions, options]),
          );
          setSavedOptions([...savedOptions, options]);
        }}
      >
        <FontAwesomeIcon fixedWidth icon={faSave} /> save current
      </button>
    </div>
  );
}

export default SavedOptions;
