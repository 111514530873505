import React, { createContext, useReducer } from "react";
import moment from "moment";
import { updateAxiosInstance } from "utils/axios";

export interface Options {
  previewBgColor: string;
  showPrice: boolean;
  showAddress: boolean;
  showHeaderFooter: boolean;
  showAffordabilityCalculations: boolean;
  quality: { label: string; value: string };
  environment?: { label: string; value: string };
  company?: {
    [key: string]: any;
  };
  id: string;
  endpoint: { label: string; value: string };
  subTypes: {
    current: { value: string; label: string };
    list: {
      value: string;
      label: string;
    }[];
  };
  linieTemplateForce: { value: string; label: string };
  reportStart: string;
  reportEnd: string;
  format: { label: string; value: string };
  reports: {
    current?: { label: string; value: string };
    list: { label: string; value: string }[];
  };
  jobs?: { label: string; value: string };
  logbook?: { label: string; value: string; type: "contact" | "property" };
  property?: {
    [key: string]: any;
  };
  contact?: {
    [key: string]: any;
  };
  lang: { value: string; label: string };
  listQuery: any;
  logbookQuery: any;
  refreshCount?: number;
  // [key: string]: any;
}

type InflateOptions =
  | {
      type: "hydrate";
      payload: Partial<Options>;
    }
  | {
      type: "refresh";
    }
  | { type: "reset"; payload: keyof Options };

const pdfBuilderOptions = localStorage.getItem("pdfBuilderOptions");
const localOptions: Options = pdfBuilderOptions
  ? JSON.parse(pdfBuilderOptions)
  : {};

const defaultOptions: Options = {
  previewBgColor: "transparent",
  showPrice: true,
  showAddress: true,
  showAffordabilityCalculations: true,
  showHeaderFooter: true,
  quality: { label: "Print", value: "print" },
  environment: undefined,
  company: undefined,
  id: "3",
  endpoint: { label: "Property", value: "property" },
  subTypes: {
    current: { value: "flyer", label: "Flyer" },
    list: [
      {
        value: "flyer",
        label: "Flyer",
      },
      {
        value: "dossier",
        label: "Dossier",
      },
    ],
  },
  linieTemplateForce: {
    value: "default",
    label: "Company settings",
  },
  reportStart: moment().format("YYYY-MM-DD"),
  reportEnd: moment().add(1, "years").format("YYYY-MM-DD"),
  format: { label: "Portrait", value: "portrait" },
  reports: { current: undefined, list: [] },
  jobs: undefined,
  logbook: undefined,
  property: undefined,
  contact: undefined,
  lang: { value: "de", label: "Deutsch" },
  listQuery: {
    "order-by": [
      {
        type: "customorderby",
        field: "created",
        alias: "",
        direction: "desc",
      },
    ],
    output: "pdf",
    filter: [
      {
        type: "eq",
        field: "propertyType",
        where: "and",
        direction: "desc",
        value: "property",
      },
      {
        type: "neq",
        field: "status",
        where: "and",
        direction: "desc",
        value: "ignore",
      },
    ],
    pagesize: 10,
    context: "property-list",
  },
  logbookQuery: {
    "order-by": [
      {
        type: "field",
        field: "start",
        direction: "desc",
      },
    ],
    filter: [
      {
        type: "notin",
        field: "type",
        values: ["reminder", "contact-merge"],
      },
      {
        type: "orx",
        where: "and",
        conditions: [
          {
            type: "neq",
            field: "doneReason",
            value: "ignore",
            where: "and",
          },
          {
            type: "isnull",
            field: "doneReason",
          },
        ],
      },
    ],
    pagesize: 10,
    context: "contact-sub2-list",
    output: "pdf",
  },
};
const initialOptions: Options = {
  ...defaultOptions,
  ...localOptions,
};
updateAxiosInstance(localOptions.environment?.value);

interface Context {
  options: Options;
  setOptions: (val: InflateOptions) => void;
}

const OptionsContext = createContext<Context>({
  options: initialOptions,
  setOptions: () => {},
});
interface OptionsContextProviderProps {
  children?: React.ReactNode;
}
const OptionsContextProvider = ({ children }: OptionsContextProviderProps) => {
  const [options, setOptions] = useReducer(
    (state: Options, action: InflateOptions) => {
      switch (action.type) {
        case "hydrate":
          const newState = { ...state, ...action.payload };
          localStorage.setItem("pdfBuilderOptions", JSON.stringify(newState));
          // keep axios baseUrl up-to-date
          if (action.payload.environment?.value) {
            updateAxiosInstance(action.payload.environment?.value);
          }
          return newState;
        case "refresh":
          return {
            ...state,
            ...{
              refreshCount: (state.refreshCount || 0) + 1,
            },
          };
        case "reset":
          return { ...state, [action.payload]: defaultOptions[action.payload] };
        default:
          return state;
      }
    },
    initialOptions,
  );

  return (
    <OptionsContext.Provider value={{ options, setOptions }}>
      {children}
    </OptionsContext.Provider>
  );
};

export { OptionsContextProvider };
export default OptionsContext;
