import { faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ErrorPreviewProps {
  errorMsg: string;
  requestedUrl?: string;
  previewURL?: string;
  clickAction?: () => void;
}

export const RefreshButton = ({ small }: { small?: boolean }) => {
  return (
    <div className="btn-small mt-3 text-center ">
      <FontAwesomeIcon fixedWidth icon={faSync} />
      {!small && <span className="ml-2">refresh</span>}
    </div>
  );
};

const ErrorPreview = ({
  errorMsg,
  requestedUrl,
  previewURL,
  clickAction,
}: ErrorPreviewProps) => {
  return (
    <div className="flex-1 flex">
      <div className="m-auto text-center text-red-600">
        <h3>
          ERROR: <br />
          {errorMsg}
        </h3>
        <p>
          <i>
            check your network requests for more information (inspector tools)
          </i>
        </p>
        <div
          style={{
            fontSize: ".8em",
          }}
        >
          {previewURL ? (
            <a
              href={previewURL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent-primary"
            >
              « try to preview error response »
            </a>
          ) : (
            <span>couldn't create error preview</span>
          )}
        </div>
        {!!requestedUrl && (
          <div
            style={{
              fontSize: ".8em",
              marginTop: "0.5em",
            }}
          >
            <a
              href={requestedUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="text-accent-primary"
            >
              « Requested URL »
            </a>
          </div>
        )}
        {clickAction && (
          <div onClick={clickAction} style={{ cursor: "pointer" }}>
            <RefreshButton />
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorPreview;
