import { updateAxiosInstanceToken } from "./axios";

export type AuthEnvs = "staging" | "production" | "test";
export const ALL_AUTH_ENVS: AuthEnvs[] = ["staging", "production", "test"];

const CS_LOCALSTORAGE_TOKEN_PREFIX = "casasoft_auth_token_";
const CS_LOCALSTORAGE_TOKEN_PREFIX_EXPIRE = `${CS_LOCALSTORAGE_TOKEN_PREFIX}expire_`;
const CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX = "casasoft_auth_refreshtoken_";
const CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX_EXPIRE = `${CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX}expire_`;

export function matchCasaoneEnvToCasaauthEnv(environment?: string): AuthEnvs {
  switch (environment) {
    case "production":
      return "production";
    case undefined:
    case "local":
    case "local-no-ssl":
    case "test":
      return "test";
    case "staging":
      return "staging";
    default:
      throw new Error(`Unknown environment: ${environment}`);
  }
}

export function recoverAccessTokensFromLocal() {
  const foundToken: {
    token: string;
    tokenExpire: number;
    refreshToken: string;
    refreshTokenExpire: number;
    env: AuthEnvs;
  }[] = [];
  ALL_AUTH_ENVS.forEach((env) => {
    const token = window.localStorage.getItem(
      `${CS_LOCALSTORAGE_TOKEN_PREFIX}${env}`,
    );
    const tokenExpire = window.localStorage.getItem(
      `${CS_LOCALSTORAGE_TOKEN_PREFIX_EXPIRE}${env}`,
    );
    const refreshToken = window.localStorage.getItem(
      `${CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX}${env}`,
    );
    const refreshTokenExpire = window.localStorage.getItem(
      `${CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX_EXPIRE}${env}`,
    );

    const refreshTokenExpireInt = refreshTokenExpire
      ? parseInt(refreshTokenExpire)
      : -1;

    if (refreshToken && refreshTokenExpireInt > Date.now()) {
      foundToken.push({
        token: token || "",
        tokenExpire: tokenExpire ? parseInt(tokenExpire) : -1,
        refreshToken: refreshToken,
        refreshTokenExpire: refreshTokenExpireInt,
        env: env,
      });
    }
  });

  return foundToken;
}

export function clearAuthTokens() {
  const logoutPromises = ALL_AUTH_ENVS.map(async (env) => {
    const refreshToken = window.localStorage.getItem(
      `${CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX}${env}`,
    );

    window.localStorage.removeItem(`${CS_LOCALSTORAGE_TOKEN_PREFIX}${env}`);
    window.localStorage.removeItem(`${CS_LOCALSTORAGE_TOKEN_PREFIX}${env}`);
    window.localStorage.removeItem(
      `${CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX}${env}`,
    );
    window.localStorage.removeItem(
      `${CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX_EXPIRE}${env}`,
    );
    try {
      const res = await fetch(
        `${authEndpointsConfig[env]}/realms/casasoft/protocol/openid-connect/logout`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            refresh_token: refreshToken || "",
            client_id: "casaone-app",
          }),
        },
      );
      if (res.status !== 204) {
        console.error("The following response was not handled");

        console.error(res);

        throw new Error("Logout status code expected to be 204");
      }
    } catch (error) {
      console.error(error);
    }
  });
  Promise.all(logoutPromises).then(() => {
    window.location.reload();
  });
}

function updateAccessToken(
  environment: AuthEnvs,
  {
    token,
    tokenExpireIn,
    refreshToken,
    refreshTokenExpireIn,
  }: {
    token: string;
    tokenExpireIn: number;
    refreshToken: string;
    refreshTokenExpireIn: number;
  },
) {
  window.localStorage.setItem(
    `${CS_LOCALSTORAGE_TOKEN_PREFIX}${environment}`,
    token,
  );
  const accessTokenExpiresInMs = tokenExpireIn * 1000;
  window.localStorage.setItem(
    `${CS_LOCALSTORAGE_TOKEN_PREFIX_EXPIRE}${environment}`,
    `${accessTokenExpiresInMs ? accessTokenExpiresInMs + Date.now() : ""}`,
  );
  window.localStorage.setItem(
    `${CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX}${environment}`,
    refreshToken,
  );
  const refreshTokenExpiresInMs = refreshTokenExpireIn * 1000;
  window.localStorage.setItem(
    `${CS_LOCALSTORAGE_REFRESHTOKEN_PREFIX_EXPIRE}${environment}`,
    `${refreshTokenExpiresInMs ? refreshTokenExpiresInMs + Date.now() : ""}`,
  );
  updateAxiosInstanceToken(environment);
}

export const authEndpointsConfig = {
  production: "https://connect.casasoft.com",
  staging: "https://connect.staging.casasoft.com",
  test: "https://connect.test.casasoft.com",
};

export type FetchTokenArgs =
  | {
      environments: AuthEnvs[];
      password: string;
      username: string;
      totp: string;
    }
  | {
      environments: AuthEnvs[];
      refreshTokens: { [key in AuthEnvs]?: string };
    };
export async function fetchToken(args: FetchTokenArgs) {
  const promises = args.environments.map(async (environment) => {
    try {
      const params = new URLSearchParams(
        "refreshTokens" in args
          ? {
              grant_type: "refresh_token",
              client_id: "casaone-app",
              refresh_token: args.refreshTokens[environment] || "",
            }
          : {
              grant_type: "password",
              username: args.username,
              password: args.password,
              client_id: "casaone-app",
              totp: args.totp,
            },
      );
      const response = await fetch(
        `${authEndpointsConfig[environment]}/realms/casasoft/protocol/openid-connect/token`,
        {
          mode: "cors",
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: params,
        },
      );

      const json = await response.json();
      if (response.status !== 200) {
        throw new Error(
          `${response.status}: ${json.error_description || "unknown error"}`,
        );
      }

      if (
        json.token_type === "Bearer" &&
        typeof json.access_token === "string" &&
        typeof json.expires_in === "number" &&
        typeof json.refresh_token === "string" &&
        typeof json.refresh_expires_in === "number"
      ) {
        updateAccessToken(environment, {
          token: json.access_token,
          tokenExpireIn: json.expires_in,
          refreshToken: json.refresh_token,
          refreshTokenExpireIn: json.refresh_expires_in,
        });
      } else {
        console.error(json);
        throw new Error("Expected valid tokens");
      }
    } catch (error) {
      throw new Error(
        `(${environment}) ${
          error instanceof Error ? error.message : "unknown"
        }`,
      );
    }
  });

  await Promise.all(promises);
}
