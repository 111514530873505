import { useContext } from "react";
import Checkbox from "../../Checkbox";
import Label from "../../Label";
import Async from "react-select/async";
import qs from "qs";
import _ from "lodash";

import OptionsContext from "contexts/OptionsContext";
import { axiosInstance } from "utils/axios";

export interface ContactInterface {
  displayName: string;
  id: string;
}

const ContactOptions = () => {
  const { options, setOptions } = useContext(OptionsContext);
  const fetchContacts = async (input: string) => {
    try {
      const contactQuery = qs.stringify({
        "order-by": [
          {
            type: "field",
            field: "created",
            direction: "desc",
          },
        ],
        filter: [
          {
            type: "search",
            value: input,
          },
        ],
        pagesize: 10,
      });

      const response = await axiosInstance.get(
        `${_.get(options.company, "value")}/contact?${contactQuery}`,
      );
      const json = await response.data;
      const contacts = _.get(json, "_embedded.contact");
      if (contacts) {
        return contacts.map((contact: ContactInterface) => ({
          label: `${contact.displayName} (${contact.id})`,
          value: contact.id,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Label label="Contact">
        <Async
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          value={options.contact}
          loadOptions={fetchContacts}
          onChange={(contact) => {
            setOptions({ type: "hydrate", payload: { contact: contact } });
          }}
        />
      </Label>
      <Label label="Kopf-/Fusszeile anzeigen">
        <Checkbox
          checked={options.showHeaderFooter}
          onChange={(value) => {
            setOptions({
              type: "hydrate",
              payload: { showHeaderFooter: value },
            });
          }}
        />
      </Label>
    </>
  );
};

export default ContactOptions;
