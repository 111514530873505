interface LabelProps {
  label: string;
  children?: React.ReactNode;
}

const Label = ({ label, children }: LabelProps) => {
  return (
    <div>
      <div className="font-bold mb-2 mt-4">{label}</div>
      {children}
    </div>
  );
};

export default Label;
