import { memo, useState, useEffect, useRef, useContext } from "react";
import Loader from "../Loader";
import ErrorPreview, { RefreshButton } from "./ErrorPreview";
import { isEqual } from "lodash";
import OptionsContext from "contexts/OptionsContext";
import { axiosInstance } from "utils/axios";

interface IFrameProps {
  debug?: boolean;
  path: string;
  requestData: { body?: unknown; method: "POST" | "GET" };
  refreshId?: number;
}
interface ResponseState {
  path: string;
  blobUrl?: string;
  error: false | string;
}

let scrollPos = 0;

const IFrame = ({ path, requestData, debug }: IFrameProps) => {
  const { options } = useContext(OptionsContext);

  const [response, setResponse] = useState<ResponseState>({
    path: "",
    error: false,
  });

  const abbortRef = useRef(new AbortController());

  const [loading, setLoading] = useState(true);
  const [refetchCount, setRefetchCount] = useState(0);

  const iFrameRef = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    if (iFrameRef.current) {
      if (iFrameRef.current.contentWindow) {
        iFrameRef.current.contentWindow.onscroll = () => {
          scrollPos = iFrameRef.current?.contentWindow?.scrollY || scrollPos;
        };
      }
    }
  }, [response]);

  const reFetch = () => {
    setRefetchCount((prevCount) => prevCount + 1);
  };
  const abortFetch = () => {
    abbortRef.current.abort();
  };

  useEffect(() => {
    setLoading(true);
    // abort previous fetch if one is still running and create a new abortcontroller
    abbortRef.current.abort();
    // const newAbortController = new AbortController();

    abbortRef.current = new AbortController();

    const axiosConfig = {
      signal: abbortRef.current.signal,
      responseType: "blob",
    } as const;
    const axiosReponse =
      requestData.method === "POST"
        ? axiosInstance.post(path, requestData.body, axiosConfig)
        : axiosInstance.get(path, axiosConfig);

    axiosReponse
      .then(async (res) => {
        if (res) {
          if (res.status !== 200) {
            const errBlob = new Blob([`${res.status}: ${res.statusText}`], {
              type: "text/html",
            });
            console.error(res);
            setResponse((r) => {
              return {
                ...r,
                blobUrl: URL.createObjectURL(errBlob),
                error: `${res?.status} (${res?.statusText})`,
                path: path,
              };
            });
          } else {
            if (!debug && res.data.size < 5000) {
              setResponse((r) => {
                return {
                  ...r,
                  path: path,
                  error: "The PDF is too small to be a PDF 🤔",
                };
              });
            } else {
              setResponse((r) => {
                return {
                  ...r,
                  blobUrl: URL.createObjectURL(res.data),
                  error: false,
                  path: path,
                };
              });
            }
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        setResponse((r) => {
          return {
            ...r,
            path: path,
            error: error
              ? `${error.name}: ${error.message}`
              : "Network error or timeout",
          };
        });
        setLoading(false);
      });

    return () => {
      abbortRef.current.abort();
    };
  }, [path, requestData, debug, refetchCount, options.environment?.value]);

  if (response.error) {
    return (
      <ErrorPreview
        errorMsg={response.error}
        previewURL={response.blobUrl}
        requestedUrl={response.path}
        clickAction={() => {
          reFetch();
        }}
      />
    );
  }
  if (loading) {
    return (
      <div
        className="flex-1 flex cursor-pointer"
        onClick={() => {
          abortFetch();
        }}
      >
        <div style={{ margin: "auto", textAlign: "center" }}>
          <Loader animate />
          <div>
            <small className="dark:text-white">click to cancle</small>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex-1 relative">
      {debug ? (
        <iframe
          className="absolute top-0 left-0 h-full w-full"
          title="iFrame HTML"
          src={response.blobUrl}
          ref={iFrameRef}
          onLoad={(e) => {
            e.currentTarget.contentWindow?.scrollTo({
              behavior: "smooth",
              top: scrollPos,
            });
          }}
        />
      ) : (
        <iframe
          className="absolute top-0 left-0 h-full w-full"
          title="iFrame PDF"
          src={response.blobUrl}
        />
      )}
      <div
        style={{
          cursor: "pointer",
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
        onClick={() => {
          reFetch();
        }}
      >
        <RefreshButton small />
      </div>
    </div>
  );
};
export default memo(IFrame, (prevProps, currProps) => {
  return isEqual(prevProps, currProps);
});
