interface LoaderProps {
  animate?: boolean;
  scale?: number;
}

const Loader = ({ animate = false, scale = 1 }: LoaderProps) => {
  return (
    <svg
      className={`${animate ? "animate-spin" : undefined}`}
      width={80 * scale}
      height={80 * scale}
    >
      <circle
        className="fill-transparent stroke-accent-primary"
        cx={`${40 * scale}`}
        cy={`${40 * scale}`}
        r={`${40 * scale - 5}`}
        strokeWidth="5"
        strokeDasharray="0 13"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Loader;
