import { useContext } from "react";
import Checkbox from "../../Checkbox";
import Label from "../../Label";
import Async from "react-select/async";
import qs from "qs";
import _ from "lodash";

import OptionsContext from "contexts/OptionsContext";

import { PropertyInterface } from "./PropertyOptions";
import { ContactInterface } from "./ContactOptions";
import { axiosInstance } from "utils/axios";

const LogbookOptions = () => {
  const { options, setOptions } = useContext(OptionsContext);
  const fetchProperties = async (input: string) => {
    try {
      const propertyQuery = qs.stringify({
        "order-by": [
          {
            type: "customorderby",
            field: "created",
            alias: "",
            direction: "desc",
          },
        ],
        filter: [
          {
            type: "search",
            value: input,
          },
          {
            type: "eq",
            field: "propertyType",
            where: "and",
            value: "property",
          },
        ],
        pagesize: 10,
      });

      const response = await axiosInstance.get(
        `/${_.get(options.company, "value")}/property?${propertyQuery}`,
        {
          method: "GET",
        },
      );
      const json = response.data();
      const properties = _.get(json, "_embedded.property");
      if (properties) {
        return properties.map((property: PropertyInterface) => ({
          label: `${_.get(property, "_embedded.contents[0].name")} (${
            property.id
          })`,
          value: property.id,
          raw: property,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };
  const fetchContacts = async (input: string) => {
    try {
      const contactQuery = qs.stringify({
        "order-by": [
          {
            type: "field",
            field: "created",
            direction: "desc",
          },
        ],
        filter: [
          {
            type: "search",
            value: input,
          },
        ],
        pagesize: 10,
      });

      const response = await axiosInstance.get(
        `/${_.get(options.company, "value")}/contact?${contactQuery}`,
      );
      const json = response.data;
      const contacts = _.get(json, "_embedded.contact");
      if (contacts) {
        return contacts.map((contact: ContactInterface) => ({
          label: `${contact.displayName} (${contact.id})`,
          value: contact.id,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Label label="Logbook Property ID">
        <Async
          isClearable
          placeholder="Start typing..."
          value={
            options.logbook?.type === "property"
              ? {
                  value: options.logbook.value,
                  label: options.logbook.label,
                }
              : undefined
          }
          loadOptions={fetchProperties}
          onChange={(newLogbook) => {
            if (!newLogbook) {
              setOptions({
                type: "reset",
                payload: "logbook",
              });
            } else if ("label" in newLogbook) {
              setOptions({
                type: "hydrate",
                payload: {
                  logbook: {
                    label: newLogbook.label,
                    value: newLogbook.value,
                    type: "property",
                  },
                },
              });
            }
          }}
        />
      </Label>
      <Label label="Logbook Contact ID">
        <Async
          isClearable
          placeholder="Start typing..."
          value={
            options.logbook?.type === "contact"
              ? {
                  value: options.logbook.value,
                  label: options.logbook.label,
                }
              : undefined
          }
          loadOptions={fetchContacts}
          onChange={(newLogbook) => {
            if (!newLogbook) {
              setOptions({
                type: "reset",
                payload: "logbook",
              });
            } else if ("label" in newLogbook) {
              setOptions({
                type: "hydrate",
                payload: {
                  logbook: {
                    label: newLogbook.label,
                    value: newLogbook.value,
                    type: "contact",
                  },
                },
              });
            }
          }}
        />
      </Label>
      <Label label="Kopf-/Fusszeile anzeigen">
        <Checkbox
          checked={options.showHeaderFooter}
          onChange={(value) => {
            setOptions({
              type: "hydrate",
              payload: { showHeaderFooter: value },
            });
          }}
        />
      </Label>
    </>
  );
};

export default LogbookOptions;
