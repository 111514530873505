import axios from "axios";
import {
  AuthEnvs,
  fetchToken,
  matchCasaoneEnvToCasaauthEnv,
  recoverAccessTokensFromLocal,
} from "./auth";

const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
};
axios.defaults.headers = {
  ...axios.defaults.headers,
  ...defaultHeaders,
};
axios.interceptors.request.use(
  async (request) => {
    const currActiveEnvToken = window.localStorage.getItem(
      "cs_active_token_env",
    );

    const activeTokens = recoverAccessTokensFromLocal().find(
      (tokens) => tokens.env === currActiveEnvToken,
    );

    if (!activeTokens) {
      throw new Error("No existing tokens found that can be used");
    }

    // if accesstokenExpire (+1second of extratime) has expired - refreshtoken first
    if (activeTokens.tokenExpire - 1000 <= Date.now()) {
      await fetchToken({
        environments: [activeTokens.env],
        refreshTokens: { [activeTokens.env]: activeTokens.refreshToken },
      });

      if ((request.headers as any)?.common?.Authorization) {
        (request.headers as any).common.Authorization =
          axios.defaults.headers.common.Authorization;
      }
    }

    return request;
  },
  (err) => Promise.reject(err),
);

export function updateAxiosInstanceToken(environment: AuthEnvs) {
  window.localStorage.setItem("cs_active_token_env", environment || "");
  const allTokens = recoverAccessTokensFromLocal();
  const currEnvToken = allTokens.find((token) => token.env === environment);
  if (currEnvToken) {
    axios.defaults.headers.common.Authorization = `Bearer ${currEnvToken.token}`;
  }
}

export function updateAxiosInstance(environment?: string) {
  const authEnv = matchCasaoneEnvToCasaauthEnv(environment);
  updateAxiosInstanceToken(authEnv);
  switch (environment) {
    case "production":
      axios.defaults.baseURL = "https://api.casaone.ch";
      break;
    case "local":
      axios.defaults.baseURL = "https://api.casaone.local:4392";
      break;
    case "local-no-ssl":
      axios.defaults.baseURL = "http://api.casaone.local:5792";
      break;
    case "test":
      axios.defaults.baseURL = "https://api.test.casaone.ch";
      break;
    case "staging":
    default:
      axios.defaults.baseURL = "https://api.staging.casaone.ch";
      break;
  }
}

export const axiosInstance = axios;
