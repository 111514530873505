import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

interface NavItemProps {
  icon: IconDefinition;
  onItemClick: () => void;
  group: "left" | "center" | "right";
  subNode?: ReactNode;
  iconColor?: string;
}

function NavItem({
  icon,
  onItemClick,
  group,
  subNode,
  iconColor,
}: NavItemProps) {
  return (
    <div
      className={`relative bg-accent-primary rounded-full text-center mx-1 flex text-white cursor-pointer ${
        group === "center" ? "w-12 h-7" : "w-7 h-7"
      }`}
      onClick={() => {
        onItemClick();
      }}
    >
      <span className="m-auto">
        <FontAwesomeIcon
          fixedWidth
          color={
            iconColor && iconColor !== "transparent" ? iconColor : undefined
          }
          icon={icon}
        />
      </span>
      {subNode}
    </div>
  );
}

interface NavProps {
  items: NavItemProps[];
}

const Nav = ({ items }: NavProps) => {
  const firstHalfChildren = items.filter((item) => item.group === "left");
  const centerChild = items.filter((item) => item.group === "center");
  const secondHalfChildren = items.filter((item) => item.group === "right");

  return (
    <div className="select-none">
      <div className="flex items-center absolute bottom-5 right-[calc(50%+2rem)]">
        {firstHalfChildren.map((item, i) => (
          <NavItem
            key={i}
            icon={item.icon}
            onItemClick={item.onItemClick}
            group={item.group}
            iconColor={item.iconColor}
          />
        ))}
      </div>
      <div className="flex items-center absolute bottom-5 left-1/2 -translate-x-1/2">
        {centerChild.map((item, i) => (
          <NavItem
            key={i}
            icon={item.icon}
            onItemClick={item.onItemClick}
            group={item.group}
            iconColor={item.iconColor}
          />
        ))}
      </div>
      <div className="flex items-center absolute bottom-5 left-[calc(50%+2rem)]">
        {secondHalfChildren.map((item, i) => (
          <NavItem
            key={i}
            icon={item.icon}
            onItemClick={item.onItemClick}
            group={item.group}
            iconColor={item.iconColor}
          />
        ))}
      </div>
    </div>
  );
};

export default Nav;
