import { useState } from "react";

interface CheckboxProps {
  checked: boolean;
  onChange: (value: boolean) => void;
}

const Checkbox = ({ checked, onChange }: CheckboxProps) => {
  const [isChecked, setChecked] = useState(checked);

  return (
    <div
      onClick={() => {
        setChecked(!isChecked);
        onChange(!isChecked);
      }}
    >
      <div
        className={`bg-white dark:bg-neutral-700 border dark:border-neutral-500 rounded-md h-5 w-5 relative cursor-pointer after:absolute after:top-1/2 after:left-1/2 after:-translate-x-1/2 after:-rotate-45 after:-translate-y-3/4 after:w-2 after:h-1 after:border-b-2 after:border-l-2 after:border-accent-primary ${
          isChecked ? "after:block" : "after:hidden"
        }`}
      ></div>
    </div>
  );
};

export default Checkbox;
