import { useContext } from "react";
import Select from "react-select";
import OptionsContext from "contexts/OptionsContext";

function EnvSelect() {
  const { options, setOptions } = useContext(OptionsContext);

  return (
    <Select
      className="my-react-select-container"
      classNamePrefix="my-react-select"
      isClearable
      value={options.environment}
      onChange={(value) => {
        if (value) {
          setOptions({
            type: "hydrate",
            payload: { environment: value as any },
          });
        } else {
          setOptions({
            type: "reset",
            payload: "environment",
          });
        }
      }}
      options={[
        {
          value: "test",
          label: "Test",
        },
        {
          value: "production",
          label: "Production",
        },
        {
          value: "staging",
          label: "Staging",
        },
        {
          value: "local",
          label: "Local",
        },
        {
          value: "local-no-ssl",
          label: "Local (no SSL)",
        },
      ]}
    />
  );
}

export default EnvSelect;
