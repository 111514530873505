import { useContext } from "react";
import Checkbox from "../../Checkbox";
import Label from "../../Label";
import Async from "react-select/async";
import qs from "qs";
import _ from "lodash";

import OptionsContext from "contexts/OptionsContext";
import { axiosInstance } from "utils/axios";

const JobOptions = () => {
  const { options, setOptions } = useContext(OptionsContext);
  const fetchJobs = async (input: string) => {
    try {
      const jobQuery = qs.stringify({
        "order-by": [
          {
            type: "field",
            field: "created",
            direction: "desc",
          },
        ],
        filter: [
          {
            type: "search",
            value: input,
          },
        ],
        pagesize: 10,
      });

      const response = await axiosInstance.get(
        `/${_.get(options.company, "value")}/job?${jobQuery}`,
      );
      const json = response.data;
      const jobs = _.get(json, "_embedded.job");
      if (jobs) {
        return jobs.map((job: any) => ({
          label: `${job._embedded?.contents?.[0].name} (${job.id})`,
          value: job.id,
        }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Label label="Job ID">
        <Async
          className="my-react-select-container"
          classNamePrefix="my-react-select"
          isClearable
          placeholder="Start typing..."
          value={options.jobs}
          loadOptions={fetchJobs}
          onChange={(newJob) => {
            if (!newJob) {
              setOptions({
                type: "reset",
                payload: "jobs",
              });
            } else if ("label" in newJob) {
              setOptions({
                type: "hydrate",
                payload: {
                  jobs: {
                    label: newJob.label,
                    value: newJob.value,
                  },
                },
              });
            }
          }}
        />
      </Label>
      <Label label="Kopf-/Fusszeile anzeigen">
        <Checkbox
          checked={options.showHeaderFooter}
          onChange={(value) => {
            setOptions({
              type: "hydrate",
              payload: { showHeaderFooter: value },
            });
          }}
        />
      </Label>
    </>
  );
};

export default JobOptions;
