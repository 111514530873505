import Checkbox from "./Checkbox";
import { useContext } from "react";
import ProfileContext from "contexts/ProfileContext";
import { ALL_AUTH_ENVS } from "utils/auth";

function LoginEnvs() {
  const { activeEnvs, setActiveEnvs } = useContext(ProfileContext);

  return (
    <div className="absolute bottom-4 left-4 text-neutral-700 dark:text-neutral-400">
      <strong>Casaauth environments</strong>
      <div>
        {ALL_AUTH_ENVS.map((env, i) => (
          <div key={i} className="flex items-center">
            <Checkbox
              checked={activeEnvs.includes(env)}
              onChange={(newVal) => {
                setActiveEnvs?.((prev) =>
                  newVal ? [...prev, env] : prev.filter((item) => item !== env),
                );
              }}
            />
            <span className="ml-2 mr-4">{env}</span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LoginEnvs;
