import { useState } from "react";
import ProfileContext from "./../contexts/ProfileContext";
import logo from "../logo.svg";
import useAuth from "hooks/useAuth";
import LoginEnvs from "./LoginEnvs";
import { AuthEnvs, recoverAccessTokensFromLocal } from "utils/auth";

interface LoginProps {
  children: React.ReactNode;
}

const Login = ({ children }: LoginProps) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [totp, setTotp] = useState("");

  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { requestTokens, authenticated } = useAuth();

  const [selectedEnvs, setSelectedEnvs] = useState<AuthEnvs[]>(
    recoverAccessTokensFromLocal().map((token) => token.env),
  );

  return (
    <ProfileContext.Provider
      value={{ activeEnvs: selectedEnvs, setActiveEnvs: setSelectedEnvs }}
    >
      {!authenticated ? (
        <div className="fixed top-p left-0 h-full w-full flex items-center text-center">
          <form
            className="m-auto"
            onSubmit={async (e) => {
              e.preventDefault();
              setIsLoading(true);
              setLoginError("");
              try {
                await requestTokens({
                  environments: selectedEnvs,
                  password,
                  username,
                  totp,
                });
              } catch (error) {
                const errorMessage =
                  error instanceof Error ? error.message : "unknown";
                setLoginError(errorMessage);
              }
              setIsLoading(false);
            }}
          >
            <img
              src={logo}
              alt="Logo"
              className="mx-auto mb-4 bg-neutral-50 dark:bg-neutral-50 rounded-md p-2"
              width={80}
            />
            <input
              className="input block"
              type="email"
              placeholder="email"
              name="email"
              autoComplete="username"
              value={username}
              onChange={(e) => {
                setUsername(e.currentTarget.value);
              }}
              required
            />
            <input
              className="input block"
              type="password"
              placeholder="password"
              name="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              required
            />
            <input
              className="input block"
              type="number"
              placeholder="totp (if available)"
              name="totp"
              value={totp}
              onChange={(e) => {
                setTotp(e.currentTarget.value);
              }}
            />
            {loginError && (
              <div className="bg-red-900 text-red-200 rounded-md py-2 px-4 mb-4 max-w-xs">
                {loginError}
              </div>
            )}

            <button
              disabled={isLoading || selectedEnvs.length === 0}
              className={`btn ${
                selectedEnvs.length === 0 ? "bg-red-500 cursor-not-allowed" : ""
              } ${isLoading ? "animate-pulse cursor-wait" : ""} `}
              type="submit"
              title={
                selectedEnvs.length === 0
                  ? "You must select at least one casaauth environment"
                  : undefined
              }
            >
              Login
            </button>
          </form>
          <LoginEnvs />
        </div>
      ) : (
        children
      )}
    </ProfileContext.Provider>
  );
};

export default Login;
