import "./index.css";
import PdfBuilder from "./components/PdfBuilder";
import Preview from "./components/PdfBuilder/Preview";
import Login from "./components/Login";
import { OptionsContextProvider } from "./contexts/OptionsContext";
import { PdfBuilderToolContextProvider } from "./contexts/PdfBuilderToolContext";
import packageJson from "../package.json";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CasaauthTokenBoundy from "components/CasaauthTokenBoundry";

const { version } = packageJson;

function Version() {
  return (
    <div className="fixed bottom-3 right-3 opacity-50 text-xs">
      <strong>CASA</strong>ONE - <strong>PDF</strong> Builder v {version}
    </div>
  );
}

const App = () => {
  return (
    <main className="h-screen relative bg-neutral-100 dark:bg-neutral-800">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Login>
                  <PdfBuilderToolContextProvider>
                    <OptionsContextProvider>
                      <CasaauthTokenBoundy>
                        <Preview />
                        <PdfBuilder />
                      </CasaauthTokenBoundy>
                    </OptionsContextProvider>
                  </PdfBuilderToolContextProvider>
                </Login>
                <Version />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
};

export default App;
