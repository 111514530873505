import React, { createContext, ReactNode, useReducer } from "react";

// this currently only sets show/hide properties, it exist so it can easily be extended in the future

interface ToolOptions {
  showDebug?: boolean;
  showPdf?: boolean;
}

interface InflatePdfOptions {
  type: "hydrate";
  payload?: any;
}

interface Context {
  toolOptions?: any;
  setToolOptions: (val: InflatePdfOptions) => void;
}

const PdfBuilderToolContext = createContext<Context>({
  setToolOptions: () => {},
});
interface PdfBuilderToolContextProviderProps {
  children: ReactNode;
}
const PdfBuilderToolContextProvider = ({
  children,
}: PdfBuilderToolContextProviderProps) => {
  const toolReducer = (state: ToolOptions, action: InflatePdfOptions) => {
    switch (action.type) {
      case "hydrate":
        const newState = { ...state, ...action.payload };
        localStorage.setItem("pdfBuilderToolOptions", JSON.stringify(newState));
        return newState;
    }
  };
  const pdfBuilderToolOptions = localStorage.getItem("pdfBuilderToolOptions");
  const localOptions = pdfBuilderToolOptions
    ? JSON.parse(pdfBuilderToolOptions)
    : {};

  const [toolOptions, setToolOptions] = useReducer(toolReducer, {
    showDebug: true,
    showPdf: true,
    ...localOptions,
  });
  return (
    <PdfBuilderToolContext.Provider value={{ toolOptions, setToolOptions }}>
      {children}
    </PdfBuilderToolContext.Provider>
  );
};

export { PdfBuilderToolContextProvider };
export default PdfBuilderToolContext;
