import OptionsContext from "contexts/OptionsContext";
import ProfileContext from "contexts/ProfileContext";
import { ReactNode, useContext } from "react";
import { clearAuthTokens, matchCasaoneEnvToCasaauthEnv } from "utils/auth";
import EnvSelect from "./PdfBuilder/EnvSelect";

interface CasaauthTokenBoundyProps {
  children: ReactNode;
}

function CasaauthTokenBoundy({ children }: CasaauthTokenBoundyProps) {
  const { activeEnvs } = useContext(ProfileContext);
  const { options } = useContext(OptionsContext);

  if (
    activeEnvs.includes(
      matchCasaoneEnvToCasaauthEnv(options.environment?.value),
    )
  ) {
    return <>{children}</>;
  }

  return (
    <div className="flex h-full">
      <div className="m-auto w-full max-w-sm p-4">
        <h2 className="text-neutral-700 dark:text-neutral-300 mb-4">
          You do not have access to this environment, please select a different
          one below:
        </h2>
        <EnvSelect />
        <div className="text-neutral-700 dark:text-neutral-300 mt-4">
          <small>
            Alternatively, change which casaauth environment you are signed
            into:
          </small>
          <button
            className="btn-small ml-3"
            onClick={(e) => {
              e.preventDefault();
              clearAuthTokens();
            }}
          >
            Change
          </button>
        </div>
      </div>
    </div>
  );
}

export default CasaauthTokenBoundy;
