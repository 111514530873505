import { ReactNode } from "react";

interface DimProps {
  onOutsideClick: () => void;
  children: ReactNode;
}

function Dim({ onOutsideClick, children }: DimProps) {
  return (
    <div
      className="transition-all bg-black bg-opacity-50 absolute top-0 left-0 w-full h-full cursor-pointer flex overflow-auto"
      onClick={(e) => {
        e.preventDefault();
        if (e.target === e.currentTarget) {
          onOutsideClick();
        }
      }}
    >
      {children}
    </div>
  );
}

export default Dim;
