import { useContext, useCallback } from "react";
import { get } from "lodash";
import OptionsContext from "contexts/OptionsContext";
import PdfBuilderToolContext from "contexts/PdfBuilderToolContext";
import qs from "qs";

import IFrame from "./IFrame";

const Preview = () => {
  const { options } = useContext(OptionsContext);
  const { toolOptions } = useContext(PdfBuilderToolContext);

  const fetchPreviews = useCallback(
    (debug = false) => {
      const getDossierOrFlyerUrl = () => {
        if (options.linieTemplateForce.value !== "default") {
          if (
            get(options.subTypes, "current.value") === "dossier" ||
            get(options.subTypes, "current.value") === "flyer"
          ) {
            return `/${options.linieTemplateForce.value}/property/${get(
              options.subTypes,
              "current.value",
            )}/${options.format.value}`;
          }
          return get(options.subTypes, "current.value")?.replace(
            /\/linie[0-9]/,
            `/${options.linieTemplateForce.value}`,
          );
        }
        // if (options.linieTemplateForce.value === "default") {
        return get(options.subTypes, "current.value");
        // }
      };

      let endpoint = "";
      let bodyObj;
      let qString = "";
      let method: "POST" | "GET" = "POST";
      switch (options.endpoint.value) {
        case "list":
          endpoint = `property-export-list`;
          qString = qs.stringify(
            {
              ...options.listQuery,
              debugHtml: debug ? "true" : undefined,
              lang: options.lang.value,
            },
            { encode: false },
          );
          method = "GET";
          break;
        case "report":
          endpoint = `generate-property-report-pdf/${get(
            options.reports,
            "current.label",
          )}/${options.lang.value}`;
          bodyObj = {
            type: options.endpoint.value,
            start: options.reportStart,
            end: options.reportEnd,
            format: "A4-simple",
            showPrice: options.showPrice,
            showAddress: options.showAddress,
            quality: options.quality.value,
            showHeaderFooter: options.showHeaderFooter,
            debug_html: debug ? "true" : undefined,
          };
          break;
        case "contact":
          endpoint = `generate-contact-address-sheet-pdf/${get(
            options.contact,
            "value",
          )}/${options.lang.value}`;
          bodyObj = {
            debug_html: debug ? "true" : undefined,
            showHeaderFooter: options.showHeaderFooter,
          };
          break;
        case "jobs":
          endpoint = `generate-jobs-pdf/${options.jobs?.value}/${options.lang.value}`;
          bodyObj = {
            debugHtml: debug ? "true" : undefined,
            showHeaderFooter: options.showHeaderFooter,
          };
          break;
        case "logbook":
          const contactOrPropertyQuery =
            options.logbook?.type === "contact"
              ? [
                  {
                    type: "orx",
                    where: "and",
                    conditions: [
                      {
                        type: "eq",
                        field: "recipient",
                        value: options.logbook.value,
                      },
                      {
                        type: "eq",
                        field: "sender",
                        value: options.logbook.value,
                      },
                    ],
                  },
                ]
              : [
                  {
                    type: "innerjoin",
                    field: "property",
                    alias: "prop",
                  },
                  {
                    parentAlias: "prop",
                    type: "leftjoin",
                    field: "parent",
                    alias: "parent",
                  },
                  {
                    type: "orx",
                    conditions: [
                      {
                        alias: "prop",
                        type: "eq",
                        field: "id",
                        value: options.logbook?.value,
                      },
                      {
                        alias: "prop",
                        type: "eq",
                        field: "origin",
                        value: options.logbook?.value,
                      },
                      {
                        alias: "prop",
                        type: "eq",
                        field: "parent",
                        value: options.logbook?.value,
                      },
                      {
                        alias: "parent",
                        type: "eq",
                        field: "parent",
                        value: options.logbook?.value,
                      },
                    ],
                  },
                ];

          endpoint = `event-export-list`;
          qString = qs.stringify(
            {
              ...options.logbookQuery,
              filter: [
                ...options.logbookQuery.filter,
                ...contactOrPropertyQuery,
              ],
              debugHtml: debug ? "true" : undefined,
              lang: options.lang.value,
            },
            { encode: false },
          );
          method = "GET";
          break;
        default:
          endpoint = `generate-pdf`;
          bodyObj = {
            id: get(options.property, "value"),
            lang: options.lang.value,
            options: {
              quality: options.quality.value,
              type: getDossierOrFlyerUrl(),
              showPrice: options.showPrice,
              showAddress: options.showAddress,
              showHeaderFooter: options.showHeaderFooter,
              showAffordability: options.showAffordabilityCalculations,
              format: options.format.value,
            },
            debugHtml: debug ? "true" : undefined,
          };
          break;
      }

      const company = get(options.company, "value");

      return {
        path: `/${company}/${endpoint}?${qString}`,
        options: {
          method: method,
          body: bodyObj,
        },
      };
    },
    [options],
  );

  const debugInit = fetchPreviews(true);
  const pdfInit = fetchPreviews(false);

  return (
    <div
      className="flex h-full"
      style={{ backgroundColor: options.previewBgColor }}
    >
      {toolOptions.showDebug && (
        <IFrame
          debug
          path={debugInit.path}
          requestData={debugInit.options}
          refreshId={options.refreshCount}
        />
      )}
      {toolOptions.showPdf && (
        <IFrame
          path={pdfInit.path}
          requestData={pdfInit.options}
          refreshId={options.refreshCount}
        />
      )}
    </div>
  );
};

export default Preview;
